import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CircleIcon from "@mui/icons-material/Circle";
import { QuatationDrawerRight } from "./Style";
import { QuotationThread } from "../../SvgIcon/SvgIcon";
import moment from "moment";
import { useState } from "react";
import { vendor_QPlus } from "../../../config/apiUrl";
import { useNavigate } from "react-router-dom";

export default function QuotationThreadDrawer(props) {
  const [selectedItem, setSelectedItem] = useState(props.CurrentTransaction);
  const navigate = useNavigate();
  const handleSelectItem = (item) => {
    // window.open(file.meta.download_link, "_blank");
    let url = `${vendor_QPlus}?call_from=qcs_plus&vendx_action=tran_details&TLGUID=${item.guid}&TType=${item.type}`;
    let obj = {
      message: "update url",
      url: url,
    };
    window.parent.postMessage(obj, "*");
    setSelectedItem(item);
    //   let currentUrl = window.location.href;

    // // Construct TLGUID parameter with item.guid value
    // const TLGUIDParam = `TLGUID=${encodeURIComponent(item.guid)}`;

    // // Check if current URL already has query parameters
    // const hasQueryParams = currentUrl.indexOf('?') !== -1;

    // // Add TLGUID parameter to the current URL
    // currentUrl += hasQueryParams ? `&${TLGUIDParam}` : `?${TLGUIDParam}`;

    // Open the updated URL in a new tab
    // if (item?.type === "Requote" || item?.type === "Quotation") {
    //   window.open(currentUrl, "_blank");
    // }
  };

  const queryParams = new URLSearchParams(window.location.search);

  const handleRfqViewFormRoute = (item) => {

  const baseUrl = `/rfq-form?inq_id=${item.id}&q=${props.newSessionID}&TLGUID=${item.guid}&vendor_id=${props.vendorDetails.id}`;
  const url = queryParams.get("supplier") ? `${baseUrl}&supplier=1` : baseUrl;

  navigate(url);
  window.location.reload(); // Force a reload
};

  return (
    <QuatationDrawerRight
      anchor="right"
      open={props?.isQuotationDrawerOpened?.right}
      sx={{ zIndex: "1311" }}
      className="SidebarDrawer"
    >
      <Card>
        <CardHeader
          avatar={<QuotationThread />}
          action={
            <Tooltip title="Close">
              <IconButton
                onClick={props.isQuotationDrawerClosed}
                aria-label="close"
                size="small"
                sx={{ color: "var(--grey-500)" }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          }
          title={
            <Typography
              variant="subtitle1"
              fontWeight={500}
              component="h6"
              color={"secondary.main"}
            >
              Transaction Thread
            </Typography>
          }
          sx={{
            "& .MuiCardHeader-action": {
              alignSelf: "center",
            },
            "& .MuiCardHeader-avatar": {
              mr: 1,
            },
          }}
        />
        <Divider />
        <CardContent sx={{ p: 0 }}>
          <Box className="Timeline-Wrap">
            <List className="Timeline-List">
              {props?.transactionThread
                ?.slice()
                .reverse()
                .map((transaction, index) => {
                  return (
                    <ListItem
                      key={index}
                      className={
                        transaction?.id === selectedItem?.id
                          ? "selected"
                          : "notSelected"
                      }
                    >
                      <ListItemAvatar>
                        {transaction.action_role === "vendor" &&
                          !["Inquiry", "Negotiation"].includes(
                            transaction.type
                          ) ? (
                          <Avatar sx={{ bgcolor: "warning.800" }}>
                            {props.vendorDetails?.name
                              ?.toUpperCase()
                              .slice(0, 1)}
                          </Avatar>
                        ) : (
                          <Avatar sx={{ bgcolor: "success.800" }}>
                            {props?.AccountUsers?.[transaction?.user_id]
                              ?.toUpperCase()
                              .slice(0, 1)}
                          </Avatar>
                        )}
                        <Box className="TimeLineConnector"></Box>
                      </ListItemAvatar>
                      <ListItemText>
                        <Stack
                          className="ListHeader"
                          direction="row"
                          spacing={2}
                          alignItems="center"
                          sx={{ mb: 1 }}
                        >
                          {transaction.action_role === "vendor" &&
                            !["Inquiry", "Negotiation"].includes(
                              transaction.type
                            ) ? (
                            <Tooltip title={props.vendorDetails?.name}>
                              <Typography
                                variant="body2"
                                fontWeight={500}
                                color={"secondary.main"}
                                noWrap
                              >
                                {props.vendorDetails?.name}
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title={
                                props?.AccountUsers?.[transaction?.user_id]
                              }
                            >
                              <Typography
                                variant="body2"
                                fontWeight={500}
                                color={"secondary.main"}
                                noWrap
                              >
                                {props?.AccountUsers?.[transaction?.user_id]}
                              </Typography>
                            </Tooltip>
                          )}
                          <Chip
                            label={
                              transaction.action_role === "vendor" &&
                                !["Inquiry", "Negotiation"].includes(
                                  transaction.type
                                )
                                ? "Vendor"
                                : "Buyer "
                            }
                            size="small"
                            variant="soft-outlined"
                          />
                        </Stack>
                        <Stack
                          className="InfoStatusChip"
                          direction="row"
                          spacing={2}
                          // onClick={() => {
                          //   transaction?.id !== selectedItem?.id &&
                          //     handleSelectItem(transaction);
                          // }}
                          onClick={() =>
                            transaction?.id !== selectedItem?.id && transaction.type === "Inquiry"
                              ? handleRfqViewFormRoute(transaction)
                              : handleSelectItem(transaction)
                          }
                        >
                          <Chip
                            label={
                              transaction.type === "Inquiry"
                                ? "RFQ"
                                : (`${transaction.action_role === "vendor" &&
                                  !["Inquiry", "Negotiation"].includes(
                                    `"${transaction.type}"`
                                  ) ? "" : "Offline"} ${transaction.type}` +
                                  " " +
                                  (transaction.sequence != null
                                    ? transaction.sequence
                                    : ""))
                            }
                            variant="soft"
                            color="primary"
                            size="small"
                            component="a"
                            href="#"
                            clickable
                          />
                          {transaction?.action && (
                            <Tooltip title={transaction.action[Object.keys(transaction?.action)]?.justification}>
                              <Chip
                                label={Object.keys(transaction?.action)}
                                variant="soft"
                                color="error"
                                size="small"
                                // component="a"
                                // href="#"
                                icon={<CircleIcon />}
                                // clickable
                                sx={{
                                  "& .MuiChip-label": {
                                    textDecoration: "none !important"
                                  },
                                  textTransform: 'capitalize'

                                }}
                              />
                            </Tooltip>
                          )}
                        </Stack>
                        <Typography
                          variant="caption"
                          color={"text.primary"}
                          component="div"
                          sx={{ wordBreak: "break-word" }}
                        >
                          {transaction?.type !== "Inquiry"
                            ? `Ref. No : ${transaction?.no}`
                            : props.referenceNo}
                        </Typography>
                        <Typography
                          variant="caption"
                          color={"text.primary"}
                          component="div"
                          sx={{ wordBreak: "break-word" }}
                        >
                          {transaction.r2a_flag == "Y" &&
                            `${transaction.type} for Auction`}
                        </Typography>
                        <Box className="DateTimeText">
                          <AccessTimeIcon />
                          {transaction.type === "Inquiry"
                            ? moment(
                              props?.rfqDate,
                              "YYYY-MM-DD HH:mm:ss"
                            ).format("DD/MM/YYYY HH:mm:ss")
                            : moment(
                              transaction?.action_date,
                              "YYYY-MM-DD HH:mm:ss"
                            ).format("DD/MM/YYYY HH:mm:ss")}
                        </Box>
                      </ListItemText>
                    </ListItem>
                  );
                })}
            </List>
          </Box>
        </CardContent>
      </Card>
    </QuatationDrawerRight>
  );
}
