import React, { useState, useEffect } from "react";
import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import UploadIcon from "@mui/icons-material/CloudUploadRounded";
import VisibilityIcon from "@mui/icons-material/VisibilityOutlined";
import dayjs from "dayjs";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import UploadFileDialog from "../../Dialogs/Upload/Upload";
import TermsAndConditions from "../../Dialogs/Upload/TermsAndConditions";
import UploadDynamicView from "../../Dialogs/Upload/UploadDynamicView";
import { getAccountModulePermission, getFeaturePermission, getMasterPermission, getUserInfo, parentHost, postFileSpecifications } from "../../../config/apiUrl";
import axios from "axios";
import ModeEditSharpIcon from '@mui/icons-material/ModeEditSharp';
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { OPEN_SNACKBAR, SET_ACCOUNT_MODULE_PERMISSION, SET_ACCOUNT_MODULE_PERMISSION_ERROR, SET_FEATURE_PERMISSION, SET_FEATURE_PERMISSION_ERROR, SET_MASTER_PERMISSION, SET_MASTER_PERMISSION_ERROR, SET_USER_INFO, SET_USER_INFO_ERROR } from "../../../redux/constants/type";
import { setFeaturePermission, setMasterPermission } from "../../../redux/actions/permissionsAction";

const RfqDetailsSectionView = (props) => {

  const [dynamicFileUploadOpen, setDynamicFileUploadOpen] = useState(false);
  const [specificationsOpen, setSpecificationsOpen] = useState(false);
  const [termsAndConditionsPopUp, setTermsAndConditionsPopUp] = useState(false);
  const [categorySpecificationFiles, setCategorySpecificationFiles] = useState(
    []
  );
  const [currentDate, setCurrentDate] = useState(new Date()); // State to hold the current date
  const [Loading, setLoading] = useState(true);
  // const [currentTemplate, setCurrentTemplate] = useState("IntlTemplate");
  const [offlineAttachment, setofflineAttachment] = useState([]);
  const [DiffrentValidityDate, setDiffrentValidityDate] = useState({});
  const [dateValues, setDateValues] = useState(null);
  const [selecetedValidDate, setSelecetedValidDate] = useState(null);
  const [RFQNoForValidityDateModal, setRFQNoForValidityDateModal] = useState(false);
  const [openValidityDateModal, setOpenValidityDateModal] = useState(false);
  const [justificationMessage, setJustificationMessage] = useState(null);
  const [MaxRfqValidity, setMaxRfqValidity] = useState('')
  const [selecetedRowValidDate, setSelecetedRowValidDate] = useState(null);
  const [ValidRFQDate, setValidRFQDate] = React.useState(null);
  const [InquiryCreatedBy, setInquiryCreatedBy] = React.useState();
  const [Profile, setProfile] = React.useState({});
  const [AccountPermission, setAccountPermission] = React.useState({});
  const [snackbar, setSnackbar] = React.useState(false);
  const [SnackMessage, setSnackMessage] = React.useState("");
  const [severity, setseverity] = React.useState("");
  const [sessionId, setSessionId] = useState(null);
  const [IframeVisible, setIframeVisible] = React.useState(false);
  const dispatch = useDispatch();
  const Permission = useSelector((state) => state.permissionsReducer);
  const userInfo = useSelector((state) => state.userInfoReducer.userInfo);
  const queryParams = new URLSearchParams(window.location.search);

  const [rfqDetails, setRfqDetails] = useState({
    Inquiry: {
      //(type=Inquiry)
      offlineRfqNo: "",
      rfqRefNo: "",
      currentDate: null,
      rfqDate: null,
      attachment: {}, //Attachment for Offline RFQ
      rfqValidityDate: null,
      rfqValidity: null,
      validTechnicalBidDate: null,
      ...(userInfo?.type !== "vendor" && {
        reminderQuoteStartDate: null,
        enableQuotationReminder: null,
        enableHourlyReminder: null,
        intervalDays: null,
      }),
      reference: "",
      currency: "",
      Specifications: [],
    },
  });

  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("xs");

  const condition = props?.data?.vendors[0]?.transaction_thread
    .slice()
    .reverse() // Reverse the array to find the last matching element first
    .find((item) => item?.template)?.type;

  const rfqDetailsData = props.CurrentTransaction;
  const reminderData = props?.data?._internal?.reminder;
  const filterData = props?.data?.filter_data;

  const getFieldsByCondition = (condition) => {
    switch (condition) {
      case "Inquiry":
        // Create the base list of fields
        let fields = [
          {
            id: "1",
            name: "offlineRfqNo",
            label: "Offline Rfq No.",
            isRequired: false,
            isReadOnly: false,
          },
          // {
          //   id: "2",
          //   name: "rfqRefNo",
          //   label: "RFQ Ref. No.",
          //   isRequired: false,
          //   isReadOnly: false,
          // },
          // {
          //   id: "4",
          //   name: "rfqDate",
          //   label: "Date of RFQ",
          //   type: "date",
          //   isRequired: false,
          //   isReadOnly: true,
          // },
          // {
          //   id: "3",
          //   name: "rfqValidity",
          //   label: "Offline RFQ Validity",
          //   type: "date",
          //   isRequired: false,
          //   isReadOnly: false,
          // },
          {
            id: "8",
            name: "reference",
            label: "Reference",
            isRequired: true,
            isReadOnly: false,
          },
          {
            id: "9",
            name: "attachment",
            label: "Offline Attachment",
            type: "file",
            isRequired: false,
            isReadOnly: false,
          },
        ];

        // Conditionally remove the fields based on user type
        if (userInfo?.type !== "vendor") {
          // Add these fields only if the user is NOT a Vendor
          fields.push(
            {
              id: "5",
              name: "reminderQuoteStartDate",
              label: "Reminder For Quote Start Date",
              type: "date",
              isRequired: false,
              isReadOnly: false,
            },
            {
              id: "6",
              name: "enableQuotationReminder",
              label: "Enable Quotation Reminder",
              type: "date",
              isRequired: false,
              isReadOnly: false,
            }
          );

          // Add the field for "Enable Hourly Reminder" only if featurePermission is "Y"
          if (Permission?.featurePermission?.hourly_quotation_reminders?.feature_value === "Y") {
            fields.push({
              id: "7",
              name: "enableHourlyReminder",
              label: "Enable Hourly Reminder",
              isRequired: true,
              isReadOnly: false,
            });
          }
          if (reminderData?.technical_bid?.valid_date_tb && filterData.technical_bid) {
            fields.push({
              id: "10",
              name: "validTechnicalBidDate",
              label: "Technical Bid Validity",
              type: "date",
              isRequired: false,
              isReadOnly: false,
            });
          }
          if (reminderData?.interval_days) {
            fields.push({
              id: "11",
              name: "intervalDays",
              label: "Interval Days",
              isRequired: false,
              isReadOnly: false,
            });
          }
        }

        // Filter out any fields that do not have a value in rfqDetails[condition]
        return fields.filter((field) => rfqDetails[condition][field.name]);

      default:
        return [];
    }
  };

  const conditionFields = getFieldsByCondition(condition);
  const setValidityDate = (prevRfqDetails, date, type) => ({
    ...prevRfqDetails,
    [`${type}Validity`]: dayjs(date, "YYYY-MM-DD HH:mm:ss").format(
      `DD/MM/YYYY HH:mm:ss [${date?.split(" ").pop()}]`
    ),
  });
  useEffect(() => {
    if (props?.data) {
      const { expiry_at, validity_date, action_date, ref_no, rfq_date, no, valid_date_tb } =
        props.data;

      const propsData = rfqDetailsData;
      const formattedDate = expiry_at;
      const rfqDate = dayjs(rfq_date, "YYYY-MM-DD HH:mm:ss").format(
        `DD/MM/YYYY HH:mm:ss [${rfq_date.split(" ").pop()}]`
      );

      const technicalBidDate = dayjs(reminderData?.technical_bid?.valid_date_tb, "YYYY-MM-DD HH:mm:ss").format(
        "DD/MM/YYYY HH:mm:ss"
      );

      let reminderValidityDate;

      if (dayjs(reminderData?.start_date, "YYYY-MM-DD", true).isValid()) {
        reminderValidityDate = dayjs(reminderData?.start_date, "YYYY-MM-DD").format(
          "DD/MM/YYYY [IST]"
        );
      } else {
        reminderValidityDate = "na IST"; // Set to your desired default for invalid dates
      }

      if (props?.CurrentTransaction?.type === "Inquiry") {
        if (props?.data?.expiry_at !== propsData?.validity_date) {
          const date = props?.data?.expiry_at;
          const type =
            propsData?.type === "Inquiry" ? "rfq" : "offlineRfq";
          setDiffrentValidityDate((prevRfqDetails) =>
            setValidityDate(prevRfqDetails, date, type)
          );
        }
      }

      const validityDate = dayjs(
        // propsData?.validity_date,
        expiry_at,
        "YYYY-MM-DD HH:mm:ss"
      ).format(
        `DD/MM/YYYY HH:mm:ss [${expiry_at.split(" ").pop()}]`
      );

      const currency =
        props?.data?.vendors[0]?.type != "Local"
          ? props?.data?.vendors[0]?.quoted_currency
          : null;
      const specifictionFile =
        props?.data?.categories[0]?.files?.length > 0
          ? props?.data?.categories[0]?.files
          : null;
      const attachment =
        propsData?.Offline_attachment?.length > 0
          ? propsData?.Offline_attachment
          : null;
      //dayjs(propsData?.quote_received_date, "YYYY-MM-DD").toDate();
      setCurrentDate(new Date());
      setRfqDetails((prevRfqDetails) => ({
        ...prevRfqDetails,
        Inquiry: {
          ...prevRfqDetails.Inquiry,
          offlineRfqNo: propsData?.no,
          rfqRefNo: ref_no,
          currentDate: currentDate,
          rfqDate: rfqDate,
          rfqValidity: validityDate,
          reference: propsData?.reference,
          currency: currency,
          Specifications: specifictionFile,
          attachment: attachment,
          // Conditionally include fields based on user type
          ...(userInfo?.type !== "vendor" && {
            reminderQuoteStartDate: reminderValidityDate,
            validTechnicalBidDate: technicalBidDate,
            intervalDays: reminderData?.interval_days,
            enableQuotationReminder: reminderData?.quotation_reminder ? "Yes" : "No",
            enableHourlyReminder: reminderData?.hourly?.reminder
              ? `Yes (${reminderData?.hourly?.reminder_hours === 1
                ? `${reminderData?.hourly?.reminder_hours} Hour`
                : `${reminderData?.hourly?.reminder_hours} Hours`})`
              : "No",
          }),
        },
      }));

    }
  }, [props.data]);

  useEffect(() => {
    // Event listener to handle messages from the iframe
    const handleMessage = (event) => {
      if (event.origin !== parentHost) return;
      if (event.data.excelClose) {
        setIframeVisible(false);
      }
    };
    window.addEventListener("message", handleMessage);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const handleUploadClose = () => {
    setDynamicFileUploadOpen(false);
    setofflineAttachment([]);
    setLoading(true);
  };

  const handleUploadOpen = (value) => {
    setDynamicFileUploadOpen(true);
    getExistingFiles(value);
  };
  const getExistingFiles = (file_id) => {
    const queryString = file_id
      .map((file) => `file_ids[]=${file.id}`)
      .join("&");

    const url = `${postFileSpecifications}?${queryString}`;
    axios
      .get(url)
      .then((res) => {
        setofflineAttachment(res.data);
        setLoading(false);
      })
      .catch((err) => {
        this.props.openSnackbar(
          "error",
          err.response.data?.message
            ? err.response.data?.message
            : "Something went wrong!"
        );
        setLoading(false);
      });
  };
  const handleUplaodSpecificationOpen = () => {
    setSpecificationsOpen(true);
  };

  const handleTermsAndConditionsOpen = () => {
    termsAndConditionsPopUp(true);
  };

  const renderField = (condition, field) => {
    // const status = "Offline";
    const status =
      props.CurrentTransaction?.action_role == "buyer" ? "Offline" : "Online";

    // const status = "Online"; // Assuming this is where you get the current status from the API
    const label =
      status === "Online" && field.label.includes("Offline")
        ? field.label.replace("Offline ", "")
        : field.label;
    if (
      (status === "Online" &&
        ["attachment", "reference"].includes(field.name)) ||
      // Exclude fields based on any other condition or criteria here
      false
    ) {
      return null; // Don't render the field
    }

    const value = rfqDetails[condition][field.name];
    if (field.name === "attachment" && field.type === "file") {
      return (
        <FormControl>
          <Typography
            component="label"
            variant="body2"
            color={"grey.500"}
            mb={1}
          >
            {label}
          </Typography>
          <Box>
            <Badge badgeContent={`${value?.length}`} color="error">
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  handleUploadOpen(value);
                  // field.name === "attachment"
                  //   ? handleUplaodSpecificationOpen()
                  //   : handleTermsAndConditionsOpen();
                }}
                startIcon={
                  <VisibilityIcon sx={{ fontSize: "22px !important" }} />
                }
              >
                View Attachment
                {/* {field.label} */}
              </Button>
            </Badge>
          </Box>
          {/* <Typography mt={1} variant="body2" color={"text.primary"}>Number Of Files {value?.length}</Typography> */}
          <Typography>
            {/* {this.state.localTemplate[cell.HTMLAttributeName]} */}
          </Typography>
          {/* {error && <FormHelperText>{ErrorMessage}</FormHelperText>} */}
        </FormControl>
      );
    } else if (
      field.name === "Specifications" &&
      field.type === "file" &&
      props?.data?.categories[0]?.files?.length > 0
    ) {
      return (
        <FormControl sx={{ width: "100%" }}>
          <Box
            sx={{
              // border: "solid 1px",
              borderColor: (theme) => theme.palette.bluegrey[500],
              borderRadius: "4px",
              minWidth: "100%",
              // height: "56px",
              marginBottom: "5px",
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="small"
              sx={{ fontSize: "12px" }}
              onClick={() => {
                handleUplaodSpecificationOpen();
              }}
            >
              <UploadIcon sx={{ marginRight: "10px" }} />
              {field.label}
            </Button>
          </Box>
          <Typography>Number Of Files {value.length}</Typography>
          <Typography>
            {/* {this.state.localTemplate[cell.HTMLAttributeName]} */}
          </Typography>
          {/* {error && <FormHelperText>{ErrorMessage}</FormHelperText>} */}
        </FormControl>
      );
    } else {
      if (!value) {
        return null;
      }
      return (
        <>
          <Typography component="label" variant="body2" color={"grey.500"}>
            {label}
          </Typography>

          <Tooltip
            // title={
            //   DiffrentValidityDate?.[field.name]
            //     ? `Previous Value : ${DiffrentValidityDate?.[field.name]}`
            //     : value
            // }
            title={
              DiffrentValidityDate?.[field.name]
                ? DiffrentValidityDate?.[field.name]
                : value
            }
            placement="bottom-start"
          >
            <Typography
              variant="body2"
              // color={
              //   DiffrentValidityDate?.[field.name]
              //     ? "error.main"
              //     : "text.primary"
              // }
              color={
                DiffrentValidityDate?.[field.name]
                && "text.primary"
              }
            >
              {value}
              {/* {field.name === "rfqValidity" && (<ModeEditSharpIcon sx={{cursor:"pointer"}} onClick={handleValidityDateOpen}/>)} */}
            </Typography>
          </Tooltip>
        </>
      );
    }
  };

  // Handle Validity date functions 

  const handleValidityDateClose = () => {
    setOpenValidityDateModal(false);
    setSelecetedRowValidDate(null);
    setJustificationMessage(null);
    setDateValues(null);
  };

  const handleJustificationChange = (e) => {
    setJustificationMessage(e.target.value);
  };

  const handleDateChange = (date) => {
    setSelecetedRowValidDate(date.$d);
  };

  const fetchPermissions = () => {
    axios.defaults.headers["Authorization"] = `Bearer ${sessionId}`;
    const url = getUserInfo;
    axios
      .get(url)
      .then((res) => {
        setProfile(res.data);
        dispatch({
          type: SET_USER_INFO,
          payload: res.data
        });
        const feturepermisson = getFeaturePermission(res.data.data.division_id);
        axios
          .get(feturepermisson)
          .then((res) => {
            setFeaturePermission(res.data);
            dispatch({
              type: SET_FEATURE_PERMISSION,
              payload: res.data
            });
          })
          .catch((err) => {
            dispatch({
              type: SET_FEATURE_PERMISSION_ERROR,
              payload: err.message
            });
          });
        const url = getMasterPermission(res.data.data.division_id);
        axios
          .get(url)
          .then((res) => {
            setMasterPermission(res.data);
            dispatch({
              type: SET_MASTER_PERMISSION,
              payload: res.data
            });
          })
          .catch((err) => {
            dispatch({
              type: SET_MASTER_PERMISSION_ERROR,
              payload: err.message
            });
          });
        const modulepermisson = getAccountModulePermission(
          res.data.data.division_id
        );
        axios
          .get(modulepermisson)
          .then((res) => {
            setAccountPermission(res.data);
            dispatch({
              type: SET_ACCOUNT_MODULE_PERMISSION,
              payload: res.data
            });
          })
          .catch((err) => {
            dispatch({
              type: SET_ACCOUNT_MODULE_PERMISSION_ERROR,
              payload: err.message
            });
          });
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          setSnackbar(true);
          setseverity("error");
          setSnackMessage(
            "Your Login session could not be validated. Please Login again..."
          );
          dispatch({
            type: OPEN_SNACKBAR,
            payload: {
              type: "error",
              msg: "Your Login session could not be validated. Please Login again..."
            }
          });
          dispatch({
            type: SET_USER_INFO_ERROR,
            payload:
              "Your Login session could not be validated. Please Login again..."
          });
        } else {
          setSnackbar(true);
          setseverity("error");
          setSnackMessage(
            error?.response?.data.message
              ? error?.response?.data.message
              : "Something went wrong"
          );
          // dispatch({
          //   type: OPEN_SNACKBAR,
          //   payload: {
          //     type: "error",
          //     msg: error?.response?.data.message
          //       ? error?.response?.data.message
          //       : "Something went wrong"
          //   }
          // });
          dispatch({
            type: SET_USER_INFO_ERROR,
            payload: error.message
          });
        }
      });
  };

  const handleSaveDate = () => {
    fetchPermissions();
  };

  const handleValidityDateOpen = () => {
    // fetchPermissions();
    setOpenValidityDateModal(true);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formatUnit = unit => String(unit).padStart(2, '0');

    return `${formatUnit(date.getDate())}/${formatUnit(date.getMonth() + 1)}/${date.getFullYear()} ` +
      `${formatUnit(date.getHours())}:${formatUnit(date.getMinutes())}:${formatUnit(date.getSeconds())}`;
  }

  const parseDateString = (dateString) => {
    var parts = dateString.split(/[\s/:]/);
    return new Date(`${parts[2]}-${parts[1]}-${parts[0]}T${parts[3]}:${parts[4]}:${parts[5]}`);
  }


  const checkRFQValidity = (rfqDateStr, extensionHours) => {
    var rfqDate = parseDateString(rfqDateStr);
    var currentDate = new Date();
    if (rfqDate < currentDate) return false;

    return (rfqDate - currentDate) / 1000 <= extensionHours * 3600;
  }


  const restrictValidityDate = (bidValidityDate, transaction_status, qouted_vendor_count) => {
    if (Permission?.featurePermission?.rfq_validity_extension?.feature_value == 'Y' && qouted_vendor_count >= 1 && (transaction_status === 'Ongoing' || transaction_status === 'Expired')) {
      const formattedDate = formatDate(bidValidityDate);
      const extension_value = Permission.featurePermission.rfq_validity_extension.feature_permission_value;
      const isValid = checkRFQValidity(formattedDate, extension_value);

      // if (isValid) {
      //   setSnackbar(true);
      //   setseverity("error");
      //   setSnackMessage(
      //     "Extension of RFQ validity has been restricted."
      //   );
      //   return 1;
      // }
    }
    return 0;
  }

  // const handleValidityDateOpens = (params) => {
  //   const { value, field, id } = params;
  //   setSelecetedValidDate(value);
  //   const dateParts = params?.row?.created_at.split(" ");
  //   if (dateParts.length === 3) {
  //     const [datePart, timePart, timeZone] = dateParts;
  //     const iso8601DateTime = `${datePart}T${timePart}`;
  //     const parsedDate = new Date(iso8601DateTime);

  //     // Check if the parsed date is a valid date object
  //     if (!isNaN(parsedDate)) {
  //       setValidRFQDate(parsedDate);
  //       const rfqValidityDate = moment(parsedDate).format(
  //         "YYYY-MM-DD HH:mm:ss"
  //       );
  //       if (Permission?.featurePermission?.max_rfq_validity_days?.feature_value == 'Y') {
  //         const inputDate = dayjs(rfqValidityDate);
  //         const maxrfqDate = inputDate.add(
  //           Permission?.featurePermission?.max_rfq_validity_days
  //             ?.feature_permission_value,
  //           "day"
  //         );

  //         setMaxRfqValidity(`${maxrfqDate.format(
  //           "DD/MM/YYYY HH:mm:ss"
  //         )}`)
  //       }
  //     }
  //   }
  //   if (
  //     params?.row?.closed_at === null &&
  //     (userInfo?.user_id == params?.row?.created_by ||
  //       userInfo?.is_super_user == "Y" ||
  //       Permission?.masterPermission?.extend_rfq == "Y")
  //   ) {
  //     if (field === "expiry_at") {
  //       const { vendor_count: { count_quoted_vendors: quotedVendorCount }, status: transactionStatus } = params.row;
  //       if (restrictValidityDate(value, transactionStatus, quotedVendorCount)) {
  //         return;
  //       }
  //       setOpenValidityDateModal(true);
  //       setInquiryCreatedBy(params?.row?.created_by);
  //       setRFQNoForValidityDateModal(params?.row?.number);
  //     }
  //     setDateValues(params);
  //   }
  // };

  useEffect(() => {
    const fetchSessionId = async () => {
      try {
        const queryParams = new URLSearchParams(window.location.search);
        const sessionId = queryParams.get("q");
        // Simulating asynchronous operation, replace with actual async fetch if needed
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setSessionId(sessionId);
      } catch (error) {
        console.error("Error fetching sessionId:", error);
      }
    };

    fetchSessionId();
  }, []);

  return (
    <>
      {props.userInfo === "buyer" &&
        (
          <Card
            variant="outlined"
            // className="template-card local_recepients_card"
            id="RfqDetails"
          >
            <CardHeader
              title={props.title}
              titleTypographyProps={{
                variant: "subtitle2",
                component: "h5",
                color: "grey.900",
                textTransform: "uppercase",
              }}
              sx={{
                minHeight: "50px",
                "& .MuiCardHeader-action": {
                  marginRight: 0,
                  marginTop: 0,
                },
              }}
              action={
                <>
                  {props.isServiceRfq && <Button
                    variant="text"
                    onClick={() => {
                      // PreviewActivity()
                      setIframeVisible(true)
                    }}
                    sx={{
                      textDecoration: "underline",
                      textTransform: "capitalize",
                      p: 0,
                      "&:hover": {
                        backgroundColor: "transparent", // Set background to transparent
                      },
                    }}
                  >
                    {`View ${props.AlternativeNames?.service_option || "Activity"} Details`}
                  </Button>}
                </>
              }
            />
            <Divider sx={{ marginBottom: "0" }} />
            <CardContent>
              {/* <Grid container spacing={2} className="local_recepients_card_content"> */}
              <Grid container spacing={2}>
                {conditionFields.map((field) => {
                  const renderedField = renderField(`${condition}`, field);
                  return (
                    renderedField && (
                      <Grid item key={field.id} xl={3} lg={4} md={4} sm={6} xs={12}>
                        {renderedField}
                      </Grid>
                    )
                  );
                })}
                {/* {conditionFields.map((field) => (
            <Grid item key={field.id} xl={3} lg={4} md={4} sm={6} xs={12}>
              {renderField(`${condition}`, field)}
            </Grid>
          ))} */}
              </Grid>
            </CardContent>
          </Card>
        )}
      {specificationsOpen && (
        <UploadFileDialog
          product_id={props.selectedCategory?.id}
          randomId={props.randomId}
          master_type="category"
          handleUplaod={handleUplaodSpecificationOpen}
          open={specificationsOpen}
          // Alreadyexistingfiles={props.categorySpecFiles}
          updateCategorySpecificationFiles={(newFiles) => {
            setCategorySpecificationFiles(newFiles);
          }}
          handleClose={() => {
            setSpecificationsOpen(false);
          }}
        />
      )}
      {termsAndConditionsPopUp && (
        <TermsAndConditions
          category_id={this.props.selectedCategory?.id}
          open={termsAndConditionsPopUp}
          termsAndConditionsData={this.state.termsAndConditionsData}
          handleClose={() => {
            setTermsAndConditionsPopUp(false);
          }}
        />
      )}
      {dynamicFileUploadOpen && (
        <UploadDynamicView
          sessionID={props.sessionId}
          Loading={Loading}
          uploadedFiles={offlineAttachment?.data}
          open={dynamicFileUploadOpen}
          handleClose={handleUploadClose}
        />
      )}
      {/* Service RFQ work */}
      <div>
        <Dialog
          open={IframeVisible}
          onClose={() => {
            setIframeVisible(false)
          }}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          fullWidth={true}
          maxWidth="false"
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
              height: "100%",
            },
          }}
          fullScreen
        >
          {/* Iframe content */}
          <iframe
            id="iframePost"
            title="Your Iframe Title"
            src={`${parentHost}/cgi-bin/vendx/iframe_pp.cgi?action=80&TLGUID=${queryParams.get("TLGUID")}&sessionID=${queryParams.get("q")}`}
            width="100%"
            height="100%"
            style={{ height: "100%", border: "none" }}
          ></iframe>
        </Dialog>
      </div>

      {/* RFQ Validity Edit*/}

      <Dialog
        open={openValidityDateModal}
        onClose={handleValidityDateClose}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        <DialogTitle
          sx={{
            "&.MuiTypography-root": {
              p: 2
            }
          }}
        >
          {RFQNoForValidityDateModal}
        </DialogTitle>
        <DialogContent sx={{ padding: "16px 16px 0 16px" }}>
          <DialogContentText>
            <Typography
              component="div"
              variant="subtitle1"
              fontWeight={500}
              color="var(--black-textcolor)"
            >
              Please select the new validity date and time:
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                fullWidth
                renderInput={(props) => <TextField {...props} />}
                ampm={false}
                // label="Select Date and Time"
                // inputFormat="L LT"
                disablePast

                value={
                  selecetedRowValidDate !== null
                    ? dayjs(selecetedRowValidDate)
                    : dayjs(selecetedValidDate)
                }
                format="DD/MM/YYYY HH:mm:ss"
                onChange={handleDateChange}
                sx={{
                  width: "100%",
                  mt: 1
                }}
                slotProps={{
                  textField: {
                    helperText: MaxRfqValidity != '' && `Maximum RFQ Validity ${MaxRfqValidity} IST`,
                    error: Error
                  },
                  layout: {
                    sx: {
                      "& .MuiDateCalendar-root, & .MuiMultiSectionDigitalClock-root":
                      {
                        maxHeight: "240px"
                      },
                      "& .MuiDateCalendar-root": {
                        width: "300px"
                      },
                      "& .MuiDayCalendar-weekDayLabel": {
                        width: "30px",
                        height: "30px"
                      },
                      "& .MuiPickersDay-root": {
                        width: "30px",
                        height: "30px"
                      },
                      "& .MuiPickersCalendarHeader-root": {
                        marginTop: "8px",
                        maxHeight: "25px",
                        minHeight: "25px"
                      }
                    }
                  }
                }}
              />
            </LocalizationProvider>
            <TextField
              autoFocus
              fullWidth
              multiline
              required={true}
              variant="outlined"
              label="Justification"
              rows={3}
              sx={{ width: "100%", resize: "none", marginTop: "8px" }}
              value={justificationMessage} // Bind the input field value to the state
              onChange={handleJustificationChange} // Handle changes to the justification text
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleValidityDateClose}
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
          {justificationMessage !== null &&
            selecetedRowValidDate !== null &&
            justificationMessage !== "" &&
            selecetedRowValidDate !== "" ? (
            <Button
              onClick={handleSaveDate}
              color="primary"
              variant="contained"
            >
              Confirm
            </Button>
          ) : (
            <Button disabled color="primary" variant="outlined">
              Confirm
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RfqDetailsSectionView;
